import * as React from 'react'
import qs from 'qs'
import { Box, Heading, Link } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authAtom } from 'stores/atoms'
import styled from 'styled-components'
import { CongratsIcon, JoinEmployeeIcon, JoinOwnerIcon } from 'components/Icons/svg'
import { useSetRecoilState } from 'recoil'
import { queryPramsAtom } from 'stores/atoms/users'
import { parseJwt } from 'lib/helper'
import { getUserByEmpNo } from 'services/user.service'
import { SelectButton } from 'components/SelectButton'
import { RegisterTemplate } from 'template/RegisterTemplate'
import palette from 'lib/styles/palette'

export const RegisterRolesPage: React.FC = () => {
  const setAuthState = useSetRecoilState(authAtom)
  const setQueryParams = useSetRecoilState(queryPramsAtom)

  const navigate = useNavigate()
  const location = useLocation()

  const qsData = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const { returnUrl } = qsData
    if (Object.keys(qsData).length) {
      setQueryParams({
        returnUrl: returnUrl as string,
      })
    }
  }, [qsData, setQueryParams])

  React.useEffect(() => {
    ;(async () => {
      try {
        const { token } = qsData

        if (Object.keys(qsData).length) {
          const empNo = parseJwt(token as string)

          const {
            data: { data, errorResponse },
          } = await getUserByEmpNo(empNo.empno)

          if (errorResponse.status !== 200) {
            return
          }

          setAuthState(data)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [qsData, setAuthState])

  return (
    <RegisterTemplate backgroundColor="#ECF3FF">
      <RoleSelectFormBlock>
        <Box>
          <Heading
            fontSize={{
              base: '16px',
              md: '24px',
            }}
            textAlign="center"
          >
            서비스 시작
          </Heading>
          <CongratsIcon className="icon" />
          <p className="description">
            통합 회원 가입을 축하드립니다.
            <br />
            우리샵 등록(원장님) 또는 근무지 설정(직원)하고,
            <br />
            <span className="emphasis">공비서 원장님을 무료로 이용해보세요.</span>
          </p>

          <SelectButtonGroup>
            <SelectButton
              icon={<JoinOwnerIcon />}
              title="우리샵 등록하기"
              description={
                <>
                  <p>샵 정보 등록하고</p>
                  <p className="emphasis">무료로 사용하기</p>
                </>
              }
              color="white"
              onClick={() => navigate('/register/owner')}
            />

            <SelectButton
              icon={<JoinEmployeeIcon />}
              title="근무지 설정하기"
              description={
                <>
                  <p>우리 매장에 계정 연결하고</p>
                  <p className="emphasis">무료로 사용하기</p>
                </>
              }
              color="white"
              onClick={() => navigate('/register/employee')}
            />
          </SelectButtonGroup>
        </Box>
      </RoleSelectFormBlock>

      <RoleSelectFooter>
        <LogoutTextButton href={process.env.REACT_APP_HOST_URL}>
          <span>로그아웃</span>
        </LogoutTextButton>
      </RoleSelectFooter>
    </RegisterTemplate>
  )
}

const RoleSelectFormBlock = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .icon {
    margin: 0 auto;
    margin-top: 4.25rem;
  }

  .description {
    margin: 0;
    text-align: center;
    color: #3e4145;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 48px;
    margin-bottom: 4rem;
    word-break: keep-all;

    .emphasis {
      font-weight: 700;
    }
  }
`

const SelectButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
`

const RoleSelectFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoutTextButton = styled(Link)`
  text-decoration-color: ${palette.default_text_gray}!important;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active,
  &:link {
    text-decoration: underline;
  }

  span {
    color: ${palette.default_text_gray};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`
