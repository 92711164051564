import media from 'lib/styles/media'
import styled from 'styled-components'

export const HeaderBlock = styled.header`
  display: flex;
  justify-content: center;
  margin-top: 42px;

  ${media.mobile} {
    display: none;
  }

  svg {
    height: 28px;
  }
`
